<template>
  <div class="container-fluid mt--6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 text-center">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/payment' }"
                >Payments and Transfers</el-breadcrumb-item
              >
              <el-breadcrumb-item>Make a Payment GBP</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-9 col-md-12" v-if="!paymented">
        <div class="card mb-3">
          <div class="card-header">
            <h3 class="mb-0">GBP Payment in UK</h3>
          </div>

          <div class="card-body" v-loading="loading">
            <div class="row justify-content-md-center">
              <div class="col-lg-8 col-md-12">
                <el-form
                  ref="formPaymentGBP"
                  :model="formPayment"
                  :rules="formPaymentRule"
                  :label-position="labelPosition"
                  size="small"
                  label-width="150px"
                >
                  <el-form-item label="Bank Account" prop="bankholderid">
                    <el-select
                      v-model="formPayment.bankholderid"
                      placeholder="Bank Account"
                      class="form-control-alternative"
                      @change="getBalance"
                      filterable
                    >
                      <el-option
                        v-for="item of bankAccount['GBP']"
                        :key="item.uid"
                        :label="`${item.currency} (${item.cardholderid})`"
                        :value="item.cardholderid"
                      ></el-option>
                    </el-select>
                    <div class="text-xs pl-3 mt-1 lh-180" v-loading="loadingBalance">
                      {{ balance }}
                      <div v-html="detailBankAccount"></div>
                    </div>
                  </el-form-item>

                  <el-form-item label="Payee" prop="payee_uid">
                    <el-select
                      v-model="formPayment.payee_uid"
                      @change="selectPayee"
                      placeholder="Select Payee"
                      class="form-control-alternative"
                      filterable
                    >
                      <el-option
                        v-for="payee of payeeList"
                        :key="payee.uid"
                        :label="`${payee.name} (acc:${payee.accountnumber} ref:${payee.reference})`"
                        :value="payee.uid"
                      >
                      </el-option>
                    </el-select>
                    <el-button v-if="isMobile" type="primary" @click="$router.push('/payee/add/thb/local-gbp?from=payment')">Add</el-button>
                  </el-form-item>

                  <el-form-item label="Payee Name">
                    <el-input
                      v-model="payeeInfo.name"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Account Number">
                    <el-input
                      v-model="payeeInfo.accountnumber"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Sort Code">
                    <el-input
                      v-model="payeeInfo.sortcode"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Amount" prop="amount">
                    <el-input
                      type="number"
                      v-model="formPayment.amount"
                      placeholder="Amount"
                      min="0"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Reference" prop="reference">
                    <el-input
                      v-model="formPayment.reference"
                      v-input-filter
                      placeholder="Reference"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Purpose of payment" prop="purpose">
                    <select-purpose
                      v-model="formPayment.purpose"
                      class="form-control-alternative"
                    ></select-purpose>
                  </el-form-item>

                  <el-form-item label="Other Purpose" prop="purposeOther"
                  v-if="formPayment.purpose === 'Other'"
                  >
                    <el-input
                      v-model="formPayment.purposeOther"
                      v-input-filter
                      placeholder="Other Purpose"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row justify-content-xl-center">
              <div class="col-xl-12 text-center">
                <el-button @click="backView()">Cancel</el-button>
                <el-button
                  type="primary"
                  @click="onSubmit()"
                  v-loading="submiting"
                  :disabled="submiting"
                  >Submit</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <payment-receipt :data="paymentReceipt" v-if="paymented" />

      <div class="col-xl-3 col-md-12" v-if="!isMobile">
        <div class="card mb-3">
          <div class="card-body">
            <a
              href="javascript:void(0)"
              @click="$router.push('/payee/add/gbp/local-gbp?from=payment')"
              class="btn btn-primary btn-block"
              >Add Payee</a
            >
          </div>
        </div>
      </div>
    </div>
    <PinDialog ref="pincodedialog"  @checked="pincodeChecked"/>
    <DeviceSettingDialog/>
    <ReviewRateDialog ref="reviewratedialog"/>
  </div>
</template>

<script>
import Utils from "@/utils";
import BankApi from "@/api/bankaccount";
import PaymentApi from "@/api/payment";
import PaymentReceipt from "./compoments/PaymentReceipt.vue";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import DeviceSettingDialog from '@/views/Device/SettingDialog.vue'
import SelectPurpose from '@/components/Input/SelectPurpose'
import ReviewRateDialog from '@/components/Review/Review'
export default {
  components: {
    PaymentReceipt,
    PinDialog,
    DeviceSettingDialog,
    SelectPurpose,
    ReviewRateDialog
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
    isFeatureBGPMulti(){
      return this.$store.getters.feature.GBPMulti
    },
  },
  data() {
    return {
      loading: false,
      loadingBalance: false,
      submiting: false,
      paymented: false,
      isPincode: false,
      payeeList: [],
      balance: "",
      payeeInfo: {
        name: "-",
        accountnumber: "-",
        sortcode: "-",
      },
      formPayment: {
        bankholderid: "",
        payee_uid: "",
        amount: "",
        product: "fasterpayment",
        reference: "",
        purpose: "",
        purposeOther: ""
      },
      formPaymentRule: {
        bankholderid: [
          { required: true, message: "Please select Bank Account", trigger: "blur" },
        ],
        payee_uid: [
          { required: true, message: "Please select Payee", trigger: "blur" },
        ],
        amount: [
          { required: true, message: "Please enter Amount", trigger: "blur" },
        ],
        purpose: [
          { required: true, message: "Please enter purpose", trigger: "blur" },
        ],
        purposeOther: [
          { required: true, message: "Please enter other purpose", trigger: "blur" },
        ],
        reference: [
          {
            required: true,
            message: "Please enter reference",
            trigger: "blur",
          },
          {message: ' Reference must put 6-18 character ',min: 6, max: 18, trigger: ['blur', 'change'] }
        ],
      },
      paymentReceipt: {},
      bankAccount: [],
      detailBankAccount: ""
    };
  },
  methods: {
    getBankAccount() {
      this.balance = "";
      this.loading = true;
      BankApi.getMultiAccount().then(({ result, data, message }) => {
        if (result) {
          this.bankAccount = data;
          this.getPayee();
        } else {
          this.$message.error(message);
        }
      });
    },
    getBalance(bankholderid) {
      this.balance = "";
      this.loadingBalance = true;
      BankApi.getAccountBalance(bankholderid).then(async ({ result, data }) => {
        this.loadingBalance = false;
        if (result) {
          this.balance = `Balance : ${data.balance}`;
          await this.getBankAccountDetail(bankholderid)
        }
      });
    },
    getPayee() {
      this.payeeList = [];
      PaymentApi.getPayeeByCurrency("gbp", "local-gbp").then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.payeeList = Utils.payeeFilter(data);;
          this.getQueryPayeeUID();
        }
      });
    },
    selectPayee(uid) {
      const payee = Utils.findObject(this.payeeList, uid, "uid");
      this.payeeInfo.name = payee.name;
      this.payeeInfo.accountnumber = payee.accountnumber;
      this.payeeInfo.sortcode = payee.sortcode;
      this.formPayment.reference = payee.reference;
    },
    getQueryPayeeUID() { 
      if (this.$route.query && this.$route.query.payee) {
        this.formPayment.payee_uid = this.$route.query.payee;
        this.selectPayee(this.formPayment.payee_uid);
      }
    },
    pincodeChecked(pincode){
      this.isPincode = pincode
      if(!pincode){
        this.submiting = false
      }else{
        this.onSubmit()
      }
    },
    onSubmit() {
      this.$refs.formPaymentGBP.validate((valid) => {
        if (valid) {
          this.submiting = true;
          if(!this.isPincode && this.isMobile){
            this.$refs.pincodedialog.show('payment')
            return false;
          }
          this.formPayment.amount = parseFloat(this.formPayment.amount);
          PaymentApi.paymentGBP(this.formPayment).then(
            ({ result, data, message }) => {
              this.submiting = false;
              if (result) {
                if (data.scapending) {
                  this.socketSubscription();
                } else {
                  this.paymentReceipt = data.receipt;
                  this.$swal(
                    `Message`,
                    `Make a Payment GBP is success`,
                    "success"
                  ).then(() => {
                    this.$emit("updateBalance");
                    this.paymented = true;
                    this.showReview();
                  });
                }
              } else {
                this.$swal("Message", message, "error");
              }
            }
          );
        }
      });
    },
    backView() {
      this.$router.push(`/payment`);
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Make Payment Pending",
        "Please confirm make payment from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("payment:" + this.plusuid, (response) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (response.result) {
          this.$swal.fire({
            icon: "success",
            title: "Make a Payment GBP is success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.paymentReceipt = response.data.receipt;
            this.$emit("updateBalance");
            this.paymented = true;
            this.showReview();
          }, 2000);
        } else {
          this.$swal("Message", response.message, "error");
        }
      });
    },
    async getBankAccountDetail(bankholderid){
      this.detailBankAccount = await Utils.getBankAccountDetail(bankholderid, this.bankAccount, true);
    },
    showReview(){
      this.$refs.reviewratedialog.show('payment_domestic', this.paymentReceipt)
    }
  },
  created() {
    this.getBankAccount();
    
  },
};
</script>

<style>
</style>